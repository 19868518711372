<template>
	<lee-page code="calculator" bg-img="/images/banner/8.jpg" titleClass="margin-top-200 light f70" title="冷库计算器" :bread="[{title:'冷库计算器'}]">
		<div class="bg-img-2"  style="background-color:#f8f9fa">
			<div class="wrap1100 padding-top-100 padding-bottom-100">
				<div class="box-shadow bg-light">
					<div class="rel hide" style="height: 130px;">
						<img src="/images/bj1.png" style="z-index:1;width: 100%;height: 216px;top:0;left:0;" class="abs"/>
						<div class="f40 rel text-center f-lq" style="z-index: 2;line-height: 100px;color:#599a11;">冷库计算器</div>
						<div class="rel text-center f12" style="z-index: 2;color: #bbb;">本计算器目前支持百余个城市的冷库建设建议查询，其他城市开发中</div>
					</div>
<!--					{{form}}-->
					<el-form class="big-form margin-top-30" :model="form" ref="form" style="padding:0 30px 30px;">
						<el-form-item label="地区" prop="province" :rules="[{required:true,message:'地区不能为空'}]">
							<el-cascader
								style="width: 500px;"
								size="large"
								:props="{ expandTrigger: 'hover',value: 'code',label: 'name',children: 'children', }"
								:options="options"
								v-model="form.province"
								@change="onChangeProvince">
							</el-cascader>
<!--							<province-city-select v-model="form.province" style="width: 500px;" @change="onChangeProvince"></province-city-select>-->
						</el-form-item>
						<div class="rel margin-top-30" style="border:1px solid #eee;padding:30px 20px 0;border-radius: 4px;">
							<div class="bg-light abs f20" style="border-left:5px solid #7ED321;top:-10px;left:20px;padding:0 30px 0 10px;">餐饮收入</div>
							<el-row :gutter="100">
								<el-col :span="12">
									<el-form-item label="餐饮收入" prop="cysr" :rules="[{required:true,message:'餐饮收入不能为空'},{pattern:$regexp.money,message:'请填入正确的金额'}]">
										<el-input v-model="form.cysr" maxlength="20" show-word-limit style="width: 100%;">
											<div slot="append" style="width: 70px;text-align: center;">亿元</div>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="平均冷库租金" prop="lkzj" :rules="[{required:true,message:'平均冷库租金不能为空'},{pattern:$regexp.money,message:'请填入正确的金额'}]">
										<el-input v-model="form.lkzj" maxlength="20" show-word-limit style="width: 100%;">
											<div slot="append" style="width: 70px;text-align: center;">元/托/天</div>
										</el-input>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
						<div class="rel margin-top-30" style="border:1px solid #eee;padding:30px 20px 0;border-radius: 4px;">
							<div class="bg-light abs f20" style="border-left:5px solid #7ED321;top:-10px;left:20px;padding:0 30px 0 10px;">生鲜食品年均消费量</div>
							<el-row :gutter="100">
								<el-col :span="12">
									<el-form-item label="人均生鲜食品消费量" prop="rjsxspxfl" :rules="[{required:true,message:'人均生鲜食品消费量不能为空'},{pattern:$regexp.money,message:'请填入正确的数量'}]">
										<el-input v-model="form.rjsxspxfl" maxlength="20" show-word-limit style="width: 100%;">
											<div slot="append" style="width: 70px;text-align: center;">KG/人/天</div>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="生鲜食品消费量" prop="sxspxfl" :rules="[{required:true,message:'生鲜食品消费量不能为空'},{pattern:$regexp.money,message:'请填入正确的消费了'}]">
										<el-input v-model="form.sxspxfl" maxlength="20" show-word-limit style="width: 100%;">
											<div slot="append" style="width: 70px;text-align: center;">万吨</div>
										</el-input>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
						<div class="rel margin-top-30" style="border:1px solid #eee;padding:30px 20px 0;border-radius: 4px;">
							<div class="bg-light abs f20" style="border-left:5px solid #7ED321;top:-10px;left:20px;padding:0 30px 0 10px;">消费能力和产业结构</div>
							<el-row :gutter="100">
								<el-col :span="12">
									<el-form-item label="常住人口" prop="czrk" :rules="[{required:true,message:'常住人口不能为空'},{pattern:$regexp.integer,message:'请填入正确的人口数量'}]">
										<el-input v-model="form.czrk" maxlength="20" show-word-limit style="width: 100%;">
											<div slot="append" style="width: 70px;text-align: center;">人</div>
										</el-input>
									</el-form-item>
								</el-col>
								<el-col :span="12">
									<el-form-item label="恩格尔系数" prop="egexs" :rules="[{required:true,message:'恩格尔系数不能为空'},{pattern:$regexp.money,message:'请填入正确的百分比'}]">
										<el-input v-model="form.egexs" maxlength="20" show-word-limit style="width: 100%;">
											<div slot="append" style="width: 70px;text-align: center;">%</div>
										</el-input>
									</el-form-item>
								</el-col>
							</el-row>
						</div>
						<div class="margin-top-20 text-center" v-if="vipInfo">
							<el-button style="width: 200px;" type="success" class="box-shadow-green" @click="toSubmit" v-if="vipInfo">{{vipInfo.msg ? vipInfo.msg : '开始计算'}}</el-button>
							<el-button style="width: 200px;" type="info" @click="toReset" v-if="vipInfo.type === 'ok'">重置</el-button>
						</div>
						<div class="f12 margin-top-20" style="color: #bbb;">
							注：本计算器还考虑了冷库平均周转率、周转次数、餐饮带动冷链业务量占比、冷链仓储收入占比等指标进行计算，计算结果仅供参考
						</div>
						<div style="border-radius: 5px;margin-top: 40px;" class="rel hide res-text">
							<img src="/images/bj2.png" class="abs" style="width: 100%;top:0;left:0;z-index: 1;"/>
							<template v-if="loading">
								<div class="rel" style="height: 100px;z-index: 2;">
									<div class="el-loading-spinner">
										<i class="el-icon-loading f30"></i>
										<div class="el-loading-text">计算中，请稍后...</div>
									</div>
								</div>
							</template>
							<template v-else>
								<VueTypewriter class="tl" ref="typewriter" :interval="50" :style="{ visibility: status }">
									<div class="rel" id="text-container" style="z-index:2;padding:20px;font-size: 18px;line-height: 40px;" v-html="info" v-if="info"></div>
								</VueTypewriter>
<!--								<vue-text-dot class="tl" ref="typewriter" :interval="50" cursorStr="">-->
<!--									<div class="rel" id="text-container" style="z-index:2;padding:20px;font-size: 18px;line-height: 40px;" v-html="info" v-if="info"></div>-->
<!--								</vue-text-dot>-->
							</template>
						</div>
					</el-form>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
import VueTypewriter from 'vue-type-writer'
export default {
	name: "index",
	components: {VueTypewriter},
	data() {
		return {
			loading:false,
			status:'hidden',
			form:{
				province:'',
				province_code:'',
				city_code:'',
				cysr:'',
				lkzj:'',
				sxspxfl:'',
				rjsxspxfl:'',
				czrk:'',
				egexs:'',
				gktotal:'',
			},
			info:null,
			vipInfo:null,
			options:[]
		}
	},
	created() {
	},
	mounted() {
		this.getProvince();
		this.checkVip();
	},
	methods: {
		checkVip(){
			let _this = this;
			this.$http.post({
				url: '/BlockVip/checkBlock',
				data: {akey: 'calculator'},
				success(res) {
					console.log(res);
					_this.vipInfo = res;
					// _this.msg = res.msg;
					// _this.type = res.type;
					// _this.data = res.data;
					//
					// _this.done = true;
				}
			});
		},
		getProvince(){
			let _this = this;
			_this.$http.post({
				url: '/Calculator/listAll',
				data: {type: 'lktotal',orderBy:'province_code',sortBy:'asc'},
				success: res => {
					if(res && res.length > 0){
						for(let i=0;i < res.length;i++){
							res[i].code = res[i].province_code;
							res[i].name = res[i].province_name;
							res[i].children = []
							let _children = JSON.parse(res[i].content_json);
							for (let j=0;j < _children.length;j++){
								if(_children[j].value && _children[j].value !== 0 && _children[j].value !== '0'){
									res[i].children.push(_children[j]);
								}
							}
							// res[i].children = JSON.parse(res[i].content_json);
						}
						_this.options = res;
					}
				}
			});
		},
		appearText(selector, text) {
			let _this = this;
			let counter = 0;
			let _list = text.split('</div>');
			for(let i=0;i < _list.length;i++){
				_list[i] += '</div>'
			}
			_this.info = '';
			const interval = setInterval(() => {
				_this.loading = false;
				if (counter <= _list.length) {
					let _text = _list[counter];
					if(_text) {
						_this.info += _list[counter]; // 添加一个字符
					}
					counter++
				} else {
					clearInterval(interval); // 所有字符都添加完毕，清除定时器
				}
			}, 1000); // 每200毫秒添加一个字符
		},
		onChangeProvince(e){
			console.log(e);
			this.form.province_code = e[0];
			// this.form.province_code = e.provinceCode;
			this.form.city_code = e[1];
			// this.form.city_code = e.cityCode;
			this.form.cysr = '';
			this.form.lkzj = '';
			this.form.sxspxfl = '';
			this.form.rjsxspxfl = '';
			this.form.czrk = '';
			this.form.egexs = '';
			this.form.gktotal = '';
			this.getDefaultValue();
		},
		getDefaultValue(){
			let _this = this;
			if(!this.form.province_code){
				return;
			}
			_this.$http.post({
				url: '/Calculator/listAll',
				data: {province_code:this.form.province_code},
				success: res => {
					console.log(res);
					for(let i=0;i < res.length;i++){
						let _content = JSON.parse(res[i].content_json);
						let _obj = _content.find(t => t.code === _this.form.city_code);
						console.log(res[i].type+'-'+_obj.value);
						// console.log(res[i].type);
						_this.form[res[i].type] = _obj.value ? parseFloat(_obj.value) : '';
					}

					if(_this.form.czrk && _this.form.rjsxspxfl && !_this.form.sxspxfl){
						_this.form.sxspxfl = parseFloat((_this.form.czrk * _this.form.rjsxspxfl / 1000 / 10000 * 365).toFixed(2));
					}
				}
			});
		},
		toSubmit(){
			let _this = this;
			if(!this.vipInfo){
				return;
			}
			if(this.vipInfo.type === 'login'){
				this.$router.push({path:'/login'});
			}else if(this.vipInfo.type === 'vip' || this.vipInfo.type === 'pay_vip' || this.vipInfo.type === 'count' || this.vipInfo.type === 'pay_count'){
				this.$router.push({path:'/user/vip'});
			}else{
				this.$refs.form.validate(valid => {
					if (valid) {
						let _data = JSON.parse(JSON.stringify(_this.form));

						_this.$http.post({
							url: '/Calculator/jisuan',
							data: _data,
							success: res => {
								console.log(res);
								_this.loading = true;
								_this.info = res;
								// console.log(document.querySelector('#text-container'));
								// _this.appearText('#text-container', res);
								setTimeout(()=>{
									_this.loading = false;
									setTimeout(()=>{
										_this.status = 'visible'
										_this.$refs.typewriter.typewriter();
									},200)
								},2000)
							}
						});
					}
				})
			}
		},
		toReset(){
			this.form.province = '';
			this.form.province_code = '';
			this.form.city_code = '';
			this.form.cysr = '';
			this.form.lkzj = '';
			this.form.sxspxfl = '';
			this.form.rjsxspxfl = '';
			this.form.czrk = '';
			this.form.egexs = '';
			this.info = null;

			setTimeout(()=>{
				this.$refs.form.clearValidate();
			},20)
		}
	}
}
</script>
<style>
.res-text{color:#777;}
.res-text b{color:#333;margin-right: 5px;}
</style>
