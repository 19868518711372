<template>
	<div v-if="data">
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">保有量分析</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="margin-top-20">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按保有量分析</span>
			</div>
			<div class="margin-top-20" v-if="data && data && data.now && data.now.content1" v-html="data.now.content1">
				{{data.now.content1 ? data.now.content1 : ''}}
			</div>
			<div class="text-center margin-top-20">
				<lee-bar-echarts
					key="bar1"
					ref="bar1"
					:grid-bottom="30"
					:grid-top="30"
					left-name="（万辆）"
					height="350px"
					show-legend
				></lee-bar-echarts>
			</div>
		</div>
		<div class="margin-top-20" v-if="!searchQuarterData || searchQuarterData === '0'">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按占商用车比例分析</span>
			</div>
			<div class="margin-top-20" v-if="data && data && data.now && data.now.content3" v-html="data.now.content3">
				{{data.now.content3 ? data.now.content3 : ''}}
			</div>
			<div class="text-center margin-top-20">
				<lee-bar-echarts
					key="bar2"
					ref="bar2"
					:grid-bottom="30"
					:grid-top="30"
					left-name="（万辆）"
					height="350px"
					show-legend
				></lee-bar-echarts>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CarByl",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataColdCar/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataColdCar/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		onClickMap(e){
			console.log(e);
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataColdCar/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bardata1 = {bar1: [], line1: [], xaxis: []};
					let _bardata2 = {bar1: [],bar2: [], line1: [], xaxis: []};

					for (let i = 0; i < res.list.length; i++) {
						_bardata1.bar1.push(res.list[i].owner);
						_bardata1.line1.push(res.list[i].tongbi);
						_bardata1.xaxis.push(res.list[i].time_str);

						if(res.list[i].year >= 2019) {
							_bardata2.bar1.push(res.list[i].owner);
							_bardata2.bar2.push(res.list[i].syc);
							_bardata2.line1.push(res.list[i].lcczb);
							_bardata2.xaxis.push(res.list[i].time_str);
						}
					}
					setTimeout(()=>{
						if(_this.$refs.china){
							_this.$refs.china.initData(_chinaData);
						}
						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '冷藏车保有量', type: 'bar', color: 'green', data: _bardata1.bar1, dw: '万辆'},
									{name: '保有量同比', type: 'line', color: 'purple', data: _bardata1.line1, dw: '%'},
								],
								_bardata1.xaxis
							);
						}

						if (_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									// {name: '冷藏车保有量', type: 'bar', color: 'green', data: _bardata2.bar1, dw: '辆'},
									{name: '商用车保有量', type: 'bar', color: 'blue', data: _bardata2.bar2, dw: '辆'},
									{name: '冷藏车占商用车比例', type: 'line', color: 'red', data: _bardata2.line1, dw: '%'},
								],
								_bardata2.xaxis
							);
						}
					},300)
				}
			})
		},
	}
}
</script>
