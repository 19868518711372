<template>
    <lee-page code="yanjiu" bg-img="/images/banner/6.jpg" titleClass="margin-top-200 light f70" title="研究报告" sub-title="Research Reports" des="" :search="false" stop-search to-path="/yanjiu/search" show-year search-time-key="push_time" height="528" :bread="[{title:'研究报告'}]">
			<div class="bg-img-3 ">
				<div class="wrap1100 margin-top-100 margin-auto padding-bottom-100">
					<el-row :gutter="100">
						<el-col :span="12">
							<div class="box-shadow hover-cell border-radius-5 hide rel hand" style="height: 450px;background: url('/images/bg_cell_1.jpg') no-repeat top right;background-size: 100% 100%;" @click="$router.push({path:'/yanjiu/industry/list'})">
								<div class="abs bg-light border-radius-5 block hover-hide" style="height:100px;bottom:0;left:0;padding:20px 30px;line-height: 60px;">
									<div class="cl">
										<lee-img-icon url="/images/icon/icon_18.png" size="60" class="z" color="#eee"></lee-img-icon>
										<span class="z f24 dark-2 margin-left-20 f-lq" style="vertical-align: middle;">行业发展报告</span>
										<i class="el-icon-right y green f24" style="margin-top:18px;"></i>
									</div>
								</div>
								<div class="text-center padding-top-100 bg-green-op abs hover-show" style="top:0;left:0;right:0;bottom:0;">
									<lee-img-icon url="/images/icon/icon_18.png" size="120" color="#fff"></lee-img-icon>
									<div class="f-lq f24 light margin-top-30">行业发展报告</div>
									<div class="text-center margin-top-30"><i class="f24 el-icon-right light"></i></div>
								</div>
							</div>
						</el-col>
						<el-col :span="12">
							<div class="box-shadow hover-cell border-radius-5 hide rel hand" style="height: 450px;background: url('/images/bg_cell_2.jpg') no-repeat top right;background-size: 100% 100%;" @click="$router.push({path:'/yanjiu/domain'})">
								<div class="abs bg-light border-radius-5 block hover-hide" style="height:100px;bottom:0;left:0;padding:20px 30px;line-height: 60px;">
									<div class="cl">
										<lee-img-icon url="/images/icon/icon_18.png" size="60" class="z" color="#eee"></lee-img-icon>
										<span class="z f24 dark-2 margin-left-20 f-lq" style="vertical-align: middle;">细分领域报告</span>
										<i class="el-icon-right y green f24" style="margin-top:18px;"></i>
									</div>
								</div>
								<div class="text-center padding-top-100 bg-green-op abs hover-show" style="top:0;left:0;right:0;bottom:0;">
									<lee-img-icon url="/images/icon/icon_18_1.png" size="120" color="#fff"></lee-img-icon>
									<div class="f-lq f24 light margin-top-30">细分领域报告</div>
									<div class="text-center margin-top-30"><i class="f24 el-icon-right light"></i></div>
								</div>
							</div>
						</el-col>
						<!--                        <el-col :span="12">-->
						<!--                            <img src="/images/bg_cell_2.png" style="width: 500px;height:450px;" class="hand box-shadow-green border-radius-5" @click="$router.push({path:'/yanjiu/list/domain'})"/>-->
						<!--                        </el-col>-->
					</el-row>
				</div>
			</div>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    methods:{

    }
}
</script>
