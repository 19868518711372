<template>
	<span>
		<el-upload
			class="avatar-uploader2"
			:disabled="!!values"
			:show-file-list="false"
			:action="$config.uploadUrl"
			:data="{key:imgKey}"
			list-type="picture-card"
			:headers="{Authorization:$user.getToken(),platform:'pc'}"
			accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.GIF,.BMP"
			ref="upload"
			:on-success="onSuccess"
			:on-exceed="onExceed"
			:file-list="fileList"
			:limit="limit"
			:auto-upload="true">
			<img v-if="values" :src="values" class="hide vm" style="height: 100%;width:100%;">
			<div class="abs upload-shadow" style="cursor: auto !important;width:100%;height:100%;top:0;left:0;display: none;" v-if="values">
				<div style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;">
					<div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
						<div style="cursor:pointer;border-radius:4px;width: 30px;height: 30px;line-height:30px;background-color: rgba(0,0,0,.5)" @click="showBigImg">
							<i class="el-icon-zoom-in" style="color: #fff !important;font-size: 14px;"></i>
						</div>
						<div style="cursor:pointer;margin-left:10px;border-radius:4px;width: 30px;height: 30px;line-height:30px;background-color: rgba(0,0,0,.5)" @click="delImg">
							<i class="el-icon-delete" style="color: #fff !important;font-size: 14px;"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="abs upload-shadow" style="width:100%;height:100%;top:0;left:0;" v-else>
				<i class="el-icon-plus"></i>
			</div>
		</el-upload>
		<el-dialog :visible.sync="show">
			<img width="100%" :src="bigImg" alt="">
		</el-dialog>
	</span>
</template>

<script>
export default {
	name: "OneImgUpload",
	data() {
		return {
			bigImg: '',
			show: false,
			imgKey: 'headImg',
			fileList: [],
			values: '',
		};
	},
	model: {
		prop: 'value',
		event: 'change'
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		limit: {
			type: Number,
			default: 1
		}
	},
	created() {
		this.values = this.value ? (this.value.indexOf('http') >= 0 ? this.value : this.$config.assetsUrl + this.value) : '';
	},
	watch: {
		value(n, o) {
			this.values = n ? (n && n.indexOf('http') >= 0 ? n : this.$config.assetsUrl + n) : '';

			if (!n) {
				this.fileList = [];
			}
		}
	},
	methods: {
		delImg() {
			this.$confirm('是否删除此图片?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.values = '';
				this.$refs.upload.clearFiles();
				this.$emit('change', '');
			}).catch(() => {});
		},
		showBigImg() {
			this.bigImg = this.values.indexOf('http') >= 0 ? this.values : this.$config.assetsUrl + this.values;
			this.show = true;
		},
		onSuccess(response, file, fileList) {
			if (response.code !== 0) {
				this.$utils.error(response.msg);
				this.fileList = [];
			} else {
				this.fileList = fileList;

				this.values = response.result.url;

				this.$emit('change', response.result.url);
				this.$emit('input', response.result);
			}
		},
		onExceed(f, fl) {
			console.log('323');
		}
	},
	destroyed() {
		console.log('dis');
	}
}
</script>
<style>
.avatar-uploader-icon2 {
	/*font-size: 28px;*/
	/*color: #fff !important;*/
	/*width: 100%;*/
	/*height: 100%;*/
	/*line-height: 148px;*/
	/*text-align: center;*/
	/*top:0;*/
	/*left:0;*/
	/*background-color: rgba(0,0,0,.3);*/
	font-size: 28px;
	color: #8c939d;
	width: 148px;
	height: 148px;
	line-height: 148px;
	text-align: center;
}

.avatar-uploader2 .el-upload {
	border: 1px solid #ccc;
	border-radius: 10px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
	background-color: #fff;
}

.avatar-uploader2 .el-upload:hover {
	border-color: #409EFF;
}

.avatar-uploader2 .el-upload:hover .upload-shadow {
	background-color: rgba(255, 255, 255, .5);
	display: block !important;
	color: #fff !important;
}

.avatar-uploader2 .el-upload:hover .avatar-uploader-icon2 {
	display: block !important;
}
</style>
